import { EN_GB } from '@clearscore-group/lib.config.i18n';
import TagIcon from '@clearscore/rainbow.icons.tag';
import TickTripleLockIcon from '@clearscore/rainbow.icons.tick-triple-lock';
import BankTickIcon from '@clearscore/rainbow.icons.bank-tick';
import ScoreIcon from '@clearscore/rainbow.icons.score-change';
import type { ReactElement, SVGProps } from 'react';

export type IconType = (props: SVGProps<SVGElement>) => ReactElement;

export default {
    [EN_GB]: {
        heroPanel: {
            heading: 'ClearSaver',
            subheading: 'Don’t wait for better rates',
            description:
                'Link your current account and you could get instant access to money-saving credit card and loan offers',
            cta: 'Sign up for free',
            disclaimer: 'We’re a credit broker not a lender.',
            cardsImageAlt: 'An offers card graphic with Zopa, Hastings and Admiral.',
        },
        benefitsPanel: {
            description: 'You could get instantly reduced rates from trusted lenders such as:',
            list: [
                {
                    Icon: TagIcon,
                    text: 'Get <0>exclusive offers</0> only on ClearScore',
                },
                {
                    Icon: TickTripleLockIcon,
                    text: 'Be clear about your choices with <0>pre-approvals*</0>',
                },
                {
                    Icon: BankTickIcon,
                    text: 'Connecting your current account is <0> quick and secure</0>',
                },
                {
                    Icon: ScoreIcon,
                    text: "Checking <0>won't hurt your credit score</0>",
                },
            ],
            info: '*Pre-approval doesn’t always guarantee acceptance, and is subject to lender’s checks of your credit score',
            phonesImageAlt: 'Two ClearScore application screens showing examples of clearsaver applications',
        },
    },
} as const;

export interface LangProps {
    heroPanel: {
        heading: string;
        subheading: string;
        description: string;
        cta: string;
        cardsImageAlt: string;
        disclaimer: string;
    };
    benefitsPanel: {
        description: string;
        list: {
            Icon: IconType;
            text: string;
        }[];
        info: string;
        phonesImageAlt: string;
    };
}
