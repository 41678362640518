import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Check from '@clearscore/rainbow.icons.success-tick';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import styles from './how-it-works.module.css';
import type { LangProps } from './lib/lang';
import lang from './lib/lang';

interface ImagesProps {
    [name: string]: IGatsbyImageData;
}

export interface HowItWorks {
    images: ImagesProps;
}

const HowItWorks = (props: HowItWorks): ReactElement => {
    const { images } = props;

    const { titleLeft, firstLeft, secondLeft, thirdLeft, titleRight, firstRight, secondRight, thirdRight }: LangProps =
        useLanguage(lang);

    const blurbsLeft = [firstLeft, secondLeft, thirdLeft];
    const blurbsRight = [firstRight, secondRight, thirdRight];

    return (
        <section className={styles.section}>
            <div className={styles.background}>
                <GatsbyImage
                    image={images.clearsaverSecondaryBackgroundDesktop}
                    alt=""
                    className={cx(styles.imgBackground, styles.showPhablet)}
                />
                <GatsbyImage
                    image={images.clearsaverSecondaryBackgroundMobile}
                    alt=""
                    className={cx(styles.imgBackground, styles.hiddenPhablet)}
                />
            </div>
            <div className={styles.howItWorks}>
                <div className={styles.leftContainer}>
                    <div className={styles.blurbContainer}>
                        <Text.H2 weight={Text.weights.BOLD}>{titleLeft}</Text.H2>
                        {blurbsLeft.map((blurb, index) => (
                            <Spacer all={{ top: Spacer.spacings.TINY }} key={blurb}>
                                <div className={styles.listItem}>
                                    <div className={styles.itemNumerate}>
                                        <Text.Body1 weight={Text.weights.BOLD}>{index + 1}</Text.Body1>
                                    </div>
                                    <Text.Body1 weight={Text.weights.BOOK}>{blurb}</Text.Body1>
                                </div>
                            </Spacer>
                        ))}
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.blurbContainer}>
                        <Text.H2 weight={Text.weights.BOLD}>{titleRight}</Text.H2>
                        {blurbsRight.map((blurb) => (
                            <Spacer all={{ top: Spacer.spacings.TINY }} key={blurb}>
                                <div className={styles.listItem}>
                                    <Check className={styles.checkIcon} />
                                    <Text.Body1 weight={Text.weights.BOOK}>{blurb}</Text.Body1>
                                </div>
                            </Spacer>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default HowItWorks;
