import { GB } from '@clearscore-group/lib.config.i18n';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

import TwoPanelSection from '../components/two-panel-section';
import StepsSection from '../components/steps-section';
import HowItWorks from '../components/how-it-works';
import FaqSection from '../components/faq-section';

interface ImagesProps {
    [name: string]: IGatsbyImageData;
}

export default {
    [GB]: {
        sectionConfig: (props: { images: ImagesProps }) => {
            const { images } = props;
            return [
                {
                    Component: TwoPanelSection,
                    id: 'TwoPanel',
                    props: {
                        images,
                    },
                },
                {
                    Component: StepsSection,
                    id: 'Steps',
                    props: {
                        images,
                    },
                },
                {
                    Component: HowItWorks,
                    id: 'HowItWorks',
                    props: {
                        images,
                    },
                },
                {
                    Component: FaqSection,
                    id: 'FaqSection',
                },
            ];
        },
    },
} as const;
