import { GB } from '@clearscore-group/lib.config.i18n';

export default {
    [GB]: {
        ctaUrl: 'https://app.clearscore.com/signup',
        badCreditScore: {
            href: '/learn/credit-score-and-report/how-to-improve-your-credit-score-in-10-easy-steps',
        },
    },
} as const;
