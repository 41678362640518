import type { ReactElement } from 'react';
import React from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Button from '@clearscore/ui.rainbow.button';
import Text from '@clearscore/ui.rainbow.text';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import useClearsaverTracking, { CTA_NAME_FOR_TRACKING_STEP } from '../../hooks/use-clearsaver-tracking';
import type { LangProps } from './lib/lang';
import lang from './lib/lang';
import marketConfig from './lib/market-config';
import styles from './steps-section.module.css';

interface ImagesProps {
    [name: string]: IGatsbyImageData;
}

export interface StepSectionSectionProps {
    images: ImagesProps;
}

const StepsSection = (props: StepSectionSectionProps): ReactElement => {
    const { cta, steps }: LangProps = useLanguage(lang);
    const { images } = props;

    const { ctaUrl } = useMarketConfig(marketConfig);

    const { trackLandingPageSignUpClicked } = useClearsaverTracking();
    return (
        <section className={styles.stepsSection} aria-labelledby="signup-here">
            <div className={styles.steps}>
                {steps.map(({ id, text }) => (
                    <div className={styles.step} key={id}>
                        <div className={styles.image}>
                            <GatsbyImage className={styles.image} image={images[id]} alt="" />
                        </div>
                        <div className={styles.text}>
                            <Text type={Text.types.MEDIUM} weight={Text.weights.BOOK}>
                                {interpolateWithComponents(text, [{ Component: Text.Strong }])}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.cta} id="signup-here">
                <Button
                    href={ctaUrl}
                    type={Button.types.PRIMARY}
                    size={Button.sizes.LARGE}
                    isResponsive
                    onClick={(): void =>
                        trackLandingPageSignUpClicked({
                            signupCtaClicked: CTA_NAME_FOR_TRACKING_STEP,
                            signupCtaClickedCopy: cta,
                        })
                    }
                >
                    {cta}
                </Button>
            </div>
        </section>
    );
};
export default StepsSection;
