import type { ReactElement } from 'react';
import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Clearsaver from '@clearscore/website.clearsaver';

import avatar from '../assets/global/clearsaver/avatar-250x250.png';
import Layout from '../components/layout';

const ClearsaverTemplate = (props: PageProps<Queries.ClearsaverTemplateQuery>): ReactElement => {
    const { data, location } = props;
    const images = {
        heroBackground: getImage(data.heroBackground),
        heroBackgroundMobile: getImage(data.heroBackgroundMobile),
        phonesImage: getImage(data.phonesImage),
        clearscoreCircle: getImage(data.clearscoreCircle),
        balloonCircle: getImage(data.balloonCircle),
        clearsaverCircle: getImage(data.clearsaverCircle),
        clearsaverSecondaryBackgroundDesktop: getImage(data.clearsaverSecondaryBackgroundDesktop),
        clearsaverSecondaryBackgroundMobile: getImage(data.clearsaverSecondaryBackgroundMobile),
    };

    return (
        <Layout
            meta={{
                title: 'The new ClearSaver from ClearScore',
                description:
                    'Sign up for free and activate ClearSaver – you could get instant access to money-saving credit card and loan offers',
                image: avatar,
            }}
            location={location.pathname}
        >
            {({ commonModules }): ReactElement => (
                <Clearsaver
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    images={images}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query ClearsaverTemplate {
        phonesImage: file(absolutePath: { regex: "/gb/clearsaver/clearsaver_phones.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        heroBackground: file(absolutePath: { regex: "/gb/clearsaver/clearsaver_background.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        heroBackgroundMobile: file(absolutePath: { regex: "/gb/clearsaver/clearsaver_background.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        clearscoreCircle: file(absolutePath: { regex: "/gb/clearsaver/clearsaver_clearscore_circle.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        balloonCircle: file(absolutePath: { regex: "/gb/clearsaver/clearsaver_balloon_circle.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        clearsaverCircle: file(absolutePath: { regex: "/gb/clearsaver/clearsaver_circle.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        clearsaverSecondaryBackgroundDesktop: file(
            absolutePath: { regex: "/gb/clearsaver/clearsaver_secondary_background_desktop.png/" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        clearsaverSecondaryBackgroundMobile: file(
            absolutePath: { regex: "/gb/clearsaver/clearsaver_secondary_background_mobile.png/" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
`;
export default ClearsaverTemplate;
