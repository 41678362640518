import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        heading: 'FAQs',
        howDoesItWork: {
            title: 'How does ClearSaver work?',
            paragraphs: [
                'All you need to do to activate ClearSaver is connect your current account and you could start saving in an instant. It’s quick and easy, and you could even be pre-approved* for your exclusive offers – so you can feel confident about your chance of approval before you apply.',
                '*Pre-approval doesn’t always guarantee acceptance, and is subject to lender’s checks of your credit score',
            ],
        },
        whyConnect: {
            title: 'Why do I get better offers when I connect my account? ',
            paragraphs: [
                'By choosing to share your information with the lenders on ClearScore, you give them a clearer picture of your income and spending – so that they can give you offers that could save you money. ',
            ],
        },
        isSafe: {
            title: 'Is it safe to connect my current account? ',
            paragraphs: ['Connecting your account is completely safe and secure, and you can unlink it any time.'],
        },
        cta: 'Sign up for free',
        disclaimer: 'Always make sure you can afford repayments.',
    },
} as const;
export interface LangProps {
    heading: string;
    howDoesItWork: {
        title: string;
        paragraphs: string[];
    };
    whyConnect: {
        title: string;
        paragraphs: string[];
    };
    isSafe: {
        title: string;
        paragraphs: string[];
    };
    cta: string;
    disclaimer: string;
}
