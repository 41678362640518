import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        titleLeft: 'How it works',
        firstLeft: 'You securely link your current account and share some information',
        secondLeft: 'Lenders get a better picture of your finances',
        thirdLeft: 'You get enhanced offers that could save you money',
        titleRight: 'Good to know',
        firstRight: 'It won’t affect your credit score',
        secondRight: 'Your information is safe and secure',
        thirdRight: 'You can stop sharing information at any time',
    },
} as const;

export interface LangProps {
    titleLeft: string;
    firstLeft: string;
    secondLeft: string;
    thirdLeft: string;
    titleRight: string;
    firstRight: string;
    secondRight: string;
    thirdRight: string;
}
