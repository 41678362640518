import type { ReactElement } from 'react';
import React, { Fragment } from 'react';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Button from '@clearscore/ui.rainbow.button';
import Stack from '@clearscore/ui.rainbow.stack';
import Text from '@clearscore/ui.rainbow.text';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import Zopa from '../../assets/zopa.svg';
import Marbles from '../../assets/marbles.svg';
import Admiral from '../../assets/admiral.svg';
import BalloonWithText from '../../assets/balloon.svg';
import type { IconType, LangProps } from './lib/lang';
import lang from './lib/lang';
import marketConfig from './lib/market-config';
import styles from './two-panel-section.module.css';
import useClearsaverTracking from '../../hooks/use-clearsaver-tracking';

interface Partners {
    id: string;
    Icon: IconType;
}

const partners: Partners[] = [
    { id: 'zopa', Icon: Zopa },
    { id: 'marbles', Icon: Marbles },
    { id: 'admiral', Icon: Admiral },
];

interface ImagesProps {
    [name: string]: IGatsbyImageData;
}

export interface TwoPanelSectionProps {
    images: ImagesProps;
}

const TwoPanelSection = (props: TwoPanelSectionProps): ReactElement => {
    const { images } = props;
    const { heroPanel, benefitsPanel }: LangProps = useLanguage(lang);
    const { ctaUrl } = useMarketConfig(marketConfig);

    const { trackLandingPageSignUpClicked } = useClearsaverTracking();
    const CTA_NAME_FOR_TRACKING = 'primary_cta';

    return (
        <section className={styles.twoPanelSection} aria-labelledby="better-rates">
            <div className={styles.heroPanel}>
                <div className={styles.heroContent}>
                    <Stack all={Stack.spacings.SMALL}>
                        <div className={styles.titleContainer}>
                            <div>
                                <div className={styles.visuallyHidden} data-qa="accessibility-heading">
                                    <Text.H1>{heroPanel.heading}</Text.H1>
                                </div>
                                <div className={styles.svgContainer}>
                                    <BalloonWithText />
                                </div>
                            </div>
                        </div>
                        <div className={styles.descriptionContainer}>
                            <Stack all={Stack.spacings.SMALL}>
                                <Text type={Text.types.MEDIUM} weight={Text.weights.BOOK} id="better-rates">
                                    {heroPanel.subheading}
                                </Text>
                                <div className={styles.descriptionMobile}>
                                    <Text.Body2 weight={Text.weights.BOOK}>{heroPanel.description}</Text.Body2>
                                </div>
                                <div className={styles.descriptionDesktop}>
                                    <Text.Body1 weight={Text.weights.BOOK}>{heroPanel.description}</Text.Body1>
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                    <div className={styles.heroCta}>
                        <Stack all={Stack.spacings.SMALL}>
                            <Button
                                href={ctaUrl}
                                size={Button.sizes.LARGE}
                                theme={Button.themes.DARK}
                                isNarrow
                                onClick={(): void =>
                                    trackLandingPageSignUpClicked({
                                        signupCtaClicked: CTA_NAME_FOR_TRACKING,
                                        signupCtaClickedCopy: heroPanel.cta,
                                    })
                                }
                            >
                                {heroPanel.cta}
                            </Button>
                            <Text.Body2 weight={Text.weights.BOOK}>{heroPanel.disclaimer}</Text.Body2>
                        </Stack>
                    </div>
                </div>
            </div>
            <div className={styles.benefits}>
                <div className={styles.benefitsContent}>
                    <div className={styles.phonesImage}>
                        <GatsbyImage image={images.phonesImage} alt={benefitsPanel.phonesImageAlt || ''} />
                    </div>
                    <div className={styles.benefitsText}>
                        <Text type={Text.types.MEDIUM} weight={Text.weights.BOOK}>
                            {benefitsPanel.description}
                        </Text>
                        <div className={styles.partners}>
                            {partners.map(({ id, Icon }) => (
                                <div key={id} className={styles.partnerLogo}>
                                    <Icon />
                                </div>
                            ))}
                        </div>
                        <div className={styles.list}>
                            {benefitsPanel.list.map(({ Icon, text }) => (
                                <Fragment key={text}>
                                    <Icon height={30} />
                                    <Text.Body1 weight={Text.weights.BOOK}>
                                        {interpolateWithComponents(text, [{ Component: Text.Strong }])}
                                    </Text.Body1>
                                </Fragment>
                            ))}
                        </div>
                        <Text.Body2 weight={Text.weights.BOOK}>{benefitsPanel.info}</Text.Body2>
                    </div>
                </div>
            </div>
            <div className={styles.backgroundDesktop}>
                <GatsbyImage className={styles.heroBackground} image={images.heroBackground} alt="" />
            </div>
            <div className={styles.backgroundMobile}>
                <GatsbyImage className={styles.heroBackground} image={images.heroBackgroundMobile} alt="" />
            </div>
        </section>
    );
};

export default TwoPanelSection;
