import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Accordion from '@clearscore/ui.rainbow.accordion';
import Button from '@clearscore/ui.rainbow.button';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Stack from '@clearscore/ui.rainbow.stack';
import Text from '@clearscore/ui.rainbow.text';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';

import useClearsaverTracking from '../../hooks/use-clearsaver-tracking';
import type { LangProps } from './lib/lang';
import lang from './lib/lang';
import marketConfig from './lib/market-config';
import styles from './faq-section.module.css';

const BoldLink = ({ children, href }: { children: string; href?: string }): ReactElement => (
    <a className={styles.link} href={href}>
        <Text.Strong>{children}</Text.Strong>
    </a>
);
const Paragraphs = ({ text, href }: { text: string[]; href?: string }): ReactElement => (
    <Stack all={Stack.spacings.LARGE}>
        {text.map((paragraph) => (
            <Text.Body1 key={paragraph}>
                {interpolateWithComponents(paragraph, [{ Component: BoldLink, props: { href } }])}
            </Text.Body1>
        ))}
    </Stack>
);

const FaqSection = (): ReactElement => {
    const { heading, howDoesItWork, whyConnect, isSafe, cta, disclaimer }: LangProps = useLanguage(lang);
    const { ctaUrl } = useMarketConfig(marketConfig);

    const { trackLandingPageSignUpClicked, trackLandingPageFaqDropdownClicked } = useClearsaverTracking();

    const CTA_NAME_FOR_TRACKING = 'faq_cta';

    return (
        <div className={styles.faqsSection}>
            <Text.H1 tag="h2">{heading}</Text.H1>
            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.TINY }}>
                <div
                    className={styles.faq}
                    onClick={(): void =>
                        trackLandingPageFaqDropdownClicked({ faqClickedTitleCopy: howDoesItWork.title })
                    }
                >
                    <Accordion title={howDoesItWork.title}>
                        <Accordion.Content>
                            <Paragraphs text={howDoesItWork.paragraphs} />
                        </Accordion.Content>
                    </Accordion>
                </div>
                <div
                    className={styles.faq}
                    onClick={(): void => trackLandingPageFaqDropdownClicked({ faqClickedTitleCopy: whyConnect.title })}
                >
                    <Accordion title={whyConnect.title}>
                        <Accordion.Content>
                            <Paragraphs text={whyConnect.paragraphs} />
                        </Accordion.Content>
                    </Accordion>
                </div>
                <div
                    className={cx(styles.faq, styles.lastFaq)}
                    onClick={(): void => trackLandingPageFaqDropdownClicked({ faqClickedTitleCopy: isSafe.title })}
                >
                    <Accordion title={isSafe.title}>
                        <Accordion.Content>
                            <Paragraphs text={isSafe.paragraphs} />
                        </Accordion.Content>
                    </Accordion>
                </div>
            </Spacer>
            <div className={styles.cta}>
                <Button
                    href={ctaUrl}
                    type={Button.types.PRIMARY}
                    size={Button.sizes.LARGE}
                    isResponsive
                    onClick={(): void =>
                        trackLandingPageSignUpClicked({
                            signupCtaClicked: CTA_NAME_FOR_TRACKING,
                            signupCtaClickedCopy: cta,
                        })
                    }
                >
                    {cta}
                </Button>
            </div>
            <div className={styles.disclaimer}>
                <Text.Body1>{disclaimer}</Text.Body1>
            </div>
        </div>
    );
};

export default FaqSection;
