import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        cta: 'Sign up for free',
        steps: [
            {
                id: 'clearscoreCircle',
                text: 'Sign up to ClearScore – <0>join 20+ million others</0>',
            },
            {
                id: 'balloonCircle',
                text: 'Get ClearSaver by connecting your current account – it’s <0>free, forever</0>',
            },
            {
                id: 'clearsaverCircle',
                text: 'You could get <0>instantly-reduced rates</0> on cards and loans ',
            },
        ],
    },
} as const;

export interface LangProps {
    cta: string;
    steps: { id: string; text: string }[];
}
