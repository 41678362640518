import type { ReactElement } from 'react';
import React from 'react';
import Template from '@clearscore/shared.website-template';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { IAppStoreConfig } from '@clearscore/shared.website-app-logos';

import marketConfig from './lib/market-config';
import styles from './clearsaver-landing.module.css';

interface ImagesProps {
    [name: string]: IGatsbyImageData;
}

interface ClearsaverProps {
    appStoreConfig: IAppStoreConfig;
    cookiePolicyConfig: {
        moreInfoLinkUrl: string;
        enabled: boolean;
        language: Record<string, string>;
    };
    images: ImagesProps;
}

const Clearsaver = (clearsaverProps: ClearsaverProps): ReactElement => {
    const { appStoreConfig, cookiePolicyConfig, images } = clearsaverProps;
    const { sectionConfig } = useMarketConfig(marketConfig);

    const sections = sectionConfig({ images });

    return (
        <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig} pullUpContent>
            <div className={styles.cards}>
                <div className={styles.sectionContainer}>
                    {sections.map((section) => {
                        const { Component, id, props } = section;
                        return <Component key={id} {...(props as any)} />;
                    })}
                </div>
            </div>
        </Template>
    );
};
export default Clearsaver;
