import useWesbiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';

export const URL_TRACKING_EVENT = '/clearsaver';
export const TRACKING_EVENT_SIGN_UP = 'fe_landing_page_signup_clicked';
export const TRACKING_EVENT_FAQ = 'fe_landing_page_faq_dropdown_clicked';
export const CTA_NAME_FOR_TRACKING = 'primary_cta';
export const CTA_NAME_FOR_TRACKING_STEP = 'clearscore_journey_cta';
export const CTA_NAME_FOR_TRACKING_FAQ = 'faq_cta';

interface TrackLandingPageSignUpClickedProps {
    signupCtaClicked: string;
    signupCtaClickedCopy: string;
}

interface TrackLandingPageFaqDropdownClicked {
    faqClickedTitleCopy: string;
}

interface UseClearsaverTrackingReturn {
    trackLandingPageSignUpClicked: (props: TrackLandingPageSignUpClickedProps) => void;
    trackLandingPageFaqDropdownClicked: (props: TrackLandingPageFaqDropdownClicked) => void;
}

const useClearsaverTracking = (): UseClearsaverTrackingReturn => {
    const track = useWesbiteTracking();
    return {
        trackLandingPageSignUpClicked: (props: TrackLandingPageSignUpClickedProps): void =>
            track({
                name: TRACKING_EVENT_SIGN_UP,
                props: {
                    landing_page_url: URL_TRACKING_EVENT,
                    signup_cta_clicked: props.signupCtaClicked,
                    signup_cta_clicked_copy: props.signupCtaClickedCopy,
                },
            }),
        trackLandingPageFaqDropdownClicked: (props: TrackLandingPageFaqDropdownClicked): void =>
            track({
                name: TRACKING_EVENT_FAQ,
                props: {
                    landing_page_url: URL_TRACKING_EVENT,
                    faq_clicked_title_copy: props.faqClickedTitleCopy,
                },
            }),
    };
};

export default useClearsaverTracking;
